import React from 'react';

import { ChangesParams, PositionModal } from 'models/common';
import {
  SettingsHostess,
  SettingsUser,
  SettingsStatus,
} from 'models/settings.model';
import { Restaurant } from 'models/restaurant.model';
import { RootState, TypeModal } from 'types/rootState';
import Modal from 'components/LegacyModal/modal';
import { Spinner } from 'ui-kit';
import {
  useAllStatuses,
  useFetchAllSourcesQuery,
  useFetchDefaultUserQuery,
  useTranslates,
} from 'features/api/dictionaries-api';
import { useFetchAllRestaurantsQuery } from 'features/api/restaurants';
import { config } from '../config';
import { useSelector } from 'react-redux';
import { appContextSelectors } from 'features/AppContex';
import { IntlProvider } from 'react-intl';
import { useLocalStorage } from 'react-use';
import { ELocales } from '../types/commons';
import moment from 'moment';
import { TimeContextProvider } from './TimeContext';

type ApplicationContextProps = {
  restaurant: Restaurant;
};

export const ModalContext = React.createContext<ModalProvider>(null!);

export class ModalProvider extends React.PureComponent<
  ApplicationContextProps,
  RootState
> {
  settingsParamsChanges: ChangesParams[] = [];
  settingsHostessModal: SettingsHostess = {
    hostess: undefined,
    restId: undefined,
  };

  settingsUserModal: SettingsUser = { user: undefined, role: undefined };
  confirmDialog: { data: any; mode: string } = { data: undefined, mode: '' };
  // todo в этом атрибуте хранится информация для модалки. Это не лучшее решение.
  paramsInfo: { text: string; icon: string } | undefined;
  settingsEditStatusModal: SettingsStatus | undefined = undefined;

  state: RootState = {
    settingsHostessModal: this.settingsHostessModal,
    settingsEditStatusModal: this.settingsEditStatusModal,
  };

  constructor(props: ApplicationContextProps) {
    super(props);
    this.setState = this.setState.bind(this);
  }

  setSettingsHostessModal = (settings: SettingsHostess) => {
    this.setState({ settingsHostessModal: settings });
  };

  setSettingsEditStatusModal = (settings: SettingsStatus) => {
    this.setState({ settingsEditStatusModal: settings });
  };

  setConfirmDialogData = (data: { data: any; mode: string }) => {
    this.confirmDialog = data;
  };

  // todo: что за условие не сильно понятно, но влияет на отображение модалок.
  // Чтобы ничего не сломать пришлось добавить новый тип модалки для ошибок
  isActiveModalNoPhone = () => {
    const { activeModal } = this.state;
    return (
      activeModal
      && activeModal.type !== TypeModal.infoPhones
      && activeModal.type !== TypeModal.infoCall
    );
  };

  activateModal = (type: TypeModal, position: PositionModal) => {
    this.setState((state) => ({
      ...state,
      activeModal:
        state.activeModal?.type === type ? undefined : { position, type },
    }));
  };

  closeModals = () => {
    const { activeModal } = this.state;

    this.settingsHostessModal = { hostess: undefined, restId: undefined };
    this.confirmDialog = { data: undefined, mode: '' };
    if (!activeModal) {
      return;
    }
    this.setState({ activeModal: undefined });
  };

  render() {
    return (
      <ModalContext.Provider value={this}>
        {this.props.children}
        {this.isActiveModalNoPhone() && (
          <React.Suspense fallback={<Spinner />}>
            <Modal
              closeModals={this.closeModals}
              active={this.isActiveModalNoPhone()}
              activeTypeModal={this.state.activeModal?.type}
              settingsParamsChanges={this.settingsParamsChanges}
              settingsHostessModal={this.state.settingsHostessModal}
              settingsUserModal={this.settingsUserModal}
              confirmDialog={this.confirmDialog}
              settingsEditStatusModal={this.state.settingsEditStatusModal}
            />
          </React.Suspense>
        )}
      </ModalContext.Provider>
    );
  }
}

const ApplicationContextWrapper: React.FC<
  Omit<ApplicationContextProps, 'restaurant'> & any
> = ({ children, ...rest }) => {
  const [userLocale] = useLocalStorage<ELocales>(
    'locale',
    config.defaultLocale
  );
  const { isSuccess: restFulfilled } = useFetchAllRestaurantsQuery();
  const { isSuccess: sourcesFulfilled } = useFetchAllSourcesQuery({});
  const { isSuccess: statusesFulfilled } = useAllStatuses();
  const { isSuccess: translatesFulfilled, translates }
    = useTranslates(userLocale);

  const allQuery: boolean[] = [
    restFulfilled,
    sourcesFulfilled,
    statusesFulfilled,
    translatesFulfilled,
  ];
  const isAllFetched = allQuery.every(Boolean);
  const restaurant = useSelector(appContextSelectors.restaurant);
  const locale = userLocale?.split('_')[0].toLowerCase();

  if (!locale) return <Spinner />;

  moment.locale(locale);

  return (
    <IntlProvider locale={locale} defaultLocale={locale} messages={translates}>
      <ModalProvider {...rest} restaurant={restaurant}>
        <TimeContextProvider>
          {!isAllFetched ? <Spinner /> : children}
        </TimeContextProvider>
      </ModalProvider>
    </IntlProvider>
  );
};

export default ApplicationContextWrapper;
