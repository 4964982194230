import { appContextSelectors } from 'features/AppContex';
import { timelineSelectors } from 'features/Timeline';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

export function useSliderDatetime() {
  const sliderTime = useSelector(timelineSelectors.getSliderTime);
  const date = useSelector(appContextSelectors.date);
  return useCallback(() => {
    const time = sliderTime();

    const hours = time.hour();
    const minutes = time.minute();
    const result = dayjs(date.valueOf())
      .tz()
      .set('hours', hours)
      .set('minutes', minutes)
      .add(
        time.clone().startOf('day').diff(dayjs().tz().startOf('day'), 'days'),
        'days'
      );
    return result;
  }, [date, sliderTime]);
}
