import React, { FC, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useCurrentHallShiftsSchedule } from 'features/api/hallschema-api';
import { BottomBar } from 'ui-kit/BottomBar/BottomBar';
import { TimelineOptions } from './TimelineOptions';
import { TimelineInput } from './TimelineInput';
import { timelineSelectors, useTimelineActions } from 'features/Timeline';
import { Button } from 'ui-kit';
import { ETranslations } from 'types/translates';

import styles from './timeline.module.scss';
import {
  appContextSelectors,
  useApplicationContextActions,
} from '../../../../features/AppContex';
import moment from 'moment';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const ShiftsTimelineComponent: FC = () => {
  const intl = useIntl();
  const { data, isFetching } = useCurrentHallShiftsSchedule();
  const { setTimestamp, setShift, setIsActualTime } = useTimelineActions();
  const { setDate } = useApplicationContextActions();
  const startHour = useSelector(timelineSelectors.startHour);
  const isActualTime = useSelector(timelineSelectors.getIsActualTime);
  const globalDate = useSelector(appContextSelectors.date);

  const getNow = () => {
    const now = moment();
    const nowDayjs = dayjs().tz();
    const hours = Math.trunc(startHour);
    const minutes = (startHour % 1) * 60;
    const startWorkingDay = nowDayjs
      .clone()
      .set('hour', hours)
      .set('minute', minutes);
    const dif = nowDayjs.diff(startWorkingDay, 'm', true);
    if (!globalDate.isSame(now, 'day')) setDate(now);
    setTimestamp(nowDayjs.valueOf());
    setShift(dif);
    if (!isActualTime) setIsActualTime(true);
  };

  // Хук для автоматического тика времени при условии, что сейчас актуальное время
  useEffect(() => {
    if (isFetching || !isActualTime) return;
    if (isActualTime) {
      getNow();
    }
    const intervalId = setInterval(getNow, 5e3);
    return () => clearInterval(intervalId);
  }, [isActualTime, startHour]);

  if (!data || data.length === 0) return null;

  return (
    <BottomBar>
      <div className={styles.container}>
        <Button
          type="button"
          variant="primary"
          onClick={getNow}
          className={styles.button}
        >
          {intl.formatMessage({ id: ETranslations.BASE_NOW })}
        </Button>
        <div>
          <TimelineOptions />
          <TimelineInput isFetching={isFetching} />
        </div>
      </div>
    </BottomBar>
  );
};

export const ShiftsTimeline = memo(ShiftsTimelineComponent);
