import React, { useCallback, useMemo } from 'react';

import { ICONS, M } from 'common/helpers';

import styles from './TableBookingList.module.scss';
import { Booking, ManagerialTable } from 'types/booking';
import { HallMode, useHallSchemaActions } from 'features/HallSchema';
import { useSelector } from 'react-redux';
import { hasBookingSelector } from 'features/BookingFormProxy/selectors';
import { useFromProxyActions } from 'features/BookingFormProxy';
import { Client } from 'models/client.model';
import { timelineSelectors } from 'features/Timeline';
import { BookingsList } from 'components/BookingsList';
import { useIntlUtils } from '../../../../hooks/useIntlUtils';
import { ETranslations } from '../../../../types/translates';
import dayjs from 'dayjs';

import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

interface BookingCardDetailListProps {
  data: Array<Booking | ManagerialTable>;
  onClick?: (booking: Booking) => void;
}

export const BookingCardDetailList: React.VFC<BookingCardDetailListProps> = ({
  data,
  onClick,
}) => {
  const { intl } = useIntlUtils();
  const getSliderTime = useSelector(timelineSelectors.getSliderTime);

  const { current, next } = useMemo(
    () =>
      data.reduce(
        (acc, book) => {
          const startBook = dayjs.tz(`${book.bookingDate} ${book.bookingTime}`);
          // todo: remove hardcode
          if (
            book.status?.system_name === 'IN_HALL'
            && getSliderTime().isSameOrAfter(startBook)
          ) {
            acc.current.push(book);
            return acc;
          }

          const endBook = startBook.clone().add(book.visitTime, 'm');
          if (getSliderTime().isBetween(startBook, endBook)) {
            acc.current.push(book);
            return acc;
          }
          acc.next.push(book);
          return acc;
        },
        {
          current: [] as Array<Booking | ManagerialTable>,
          next: [] as Array<Booking | ManagerialTable>,
        }
      ),
    [data, getSliderTime]
  );

  const userSelectBooking = useSelector(hasBookingSelector);
  const { switchMode } = useHallSchemaActions();
  const { setBooking, resetBooking, setEditMode } = useFromProxyActions();

  const handleCardClick = useCallback(
    (booking: Booking) => {
      if (onClick) {
        onClick(booking);
        return;
      }
      const { client } = booking;
      if (userSelectBooking) {
        resetBooking();
        switchMode(HallMode.TABLE_BOOKINGS_LIST);
      } else {
        setBooking({ booking, client: client as unknown as Client });
        switchMode(HallMode.TABLE_BOOKINGS_EDIT);
      }
    },
    [userSelectBooking, onClick]
  );

  const handleEditClick = useCallback(
    (booking: Booking) => {
      handleCardClick(booking);
      setEditMode(true);
    },
    [handleCardClick, setEditMode]
  );

  return (
    <>
      {Boolean(current.length) && (
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <img src={ICONS.curBooking} alt="" />
            <span>
              {intl.formatMessage({ id: ETranslations.CURRENT_BOOKING })}
            </span>
          </div>
          <div className={styles.sectionContent}>
            <BookingsList
              bookings={current}
              withActions
              onClick={handleCardClick}
              allowMove
              onEdit={handleEditClick}
              compact
            />
          </div>
        </div>
      )}
      {Boolean(next.length) && (
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <img src={ICONS.futureBookings} alt="" />
            <span>
              {intl.formatMessage({ id: ETranslations.UPCOMING_BOOKINGS })}
            </span>
          </div>
          <div className={styles.sectionContent}>
            <BookingsList
              bookings={next}
              withActions
              onClick={handleCardClick}
              allowMove
              onEdit={handleEditClick}
              compact
            />
          </div>
        </div>
      )}
    </>
  );
};
