import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { HallMode, useHallSchemaActions } from 'features/HallSchema';
import {
  moveBookingSelectors,
  useMoveBookingActions,
} from 'features/MoveBooking';
import { useSliderDatetime } from 'hooks/useSliderDatetime';
import { hallModeSelector } from 'features/HallSchema/selectors';
import {
  useTableBookingListActions,
  useSelectedTableId,
} from 'features/TableBooking/slice';

import { getType } from '../../Table/utils';
import { Booking } from 'types/booking';
import dayjs from 'dayjs';

type TGetTableAction = {
  tableId: number;
  booking?: Booking;
  tableNumber: string;
};

export function useGetTableAction(props: TGetTableAction) {
  const { tableId, tableNumber, booking } = props;
  const { removeTargetTable, selectTargetBooking, addTargetTables }
    = useMoveBookingActions();
  const { selectTableInHall, switchMode } = useHallSchemaActions();
  const { setTable, reset } = useTableBookingListActions();
  const timeWithDate = useSliderDatetime();

  const selectedTableId = useSelector(useSelectedTableId);
  const hallMode = useSelector(hallModeSelector);
  const modalType = getType(hallMode, booking?.bookingId);

  const isTableSelected = moveBookingSelectors.isTableSelectedFactory(tableId);
  const isSelected = useSelector(isTableSelected);

  const checkBookingTime = () => {
    if (!booking) return false;

    const timeBooking = dayjs
      .tz(`${booking.bookingDate} ${booking.bookingTime}`)
      .add(booking.visitTime);

    return timeWithDate().isSameOrBefore(timeBooking);
  };

  const isBookingAfter = checkBookingTime();

  const captureAction = () => {
    const bookingId = booking?.bookingId;
    const payload = { tableId, bookingId, tableNumber };

    if (isSelected) return removeTargetTable(payload);

    if (bookingId && isBookingAfter) return selectTargetBooking(payload);

    return addTargetTables(payload);
  };

  const handleTableClick = (e: React.MouseEvent) => {
    e.preventDefault();
    switch (modalType) {
      case 'swap-captured':
        return captureAction();
      case 'select-table':
        selectedTableId && reset();
        return selectTableInHall(tableId);
      default: {
        setTable(tableId);
        return hallMode === HallMode.TABLE_BOOKINGS_LIST
          ? selectTableInHall(tableId)
          : switchMode(HallMode.TABLE_BOOKINGS_LIST);
      }
    }
  };

  return { handleTableClick, selectedTableId };
}

type TIsEndingSoonProps = {
  booking?: Booking;
  tableColor: string;
};

export function useIsEndingSoon({ booking, tableColor }: TIsEndingSoonProps) {
  const timeWithDate = useSliderDatetime();

  return useMemo(() => {
    if (!booking || tableColor !== 'green') return false;
    const timeOnLine = timeWithDate();
    const timeBooking = dayjs
      .tz(`${booking?.bookingDate} ${booking?.bookingTime}`)
      .add(booking?.visitTime, 'minute');
    return timeBooking.diff(timeOnLine, 'minute') <= 20;
  }, [booking, timeWithDate, tableColor]);
}
