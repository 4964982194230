import { Notification } from 'services/notification';
import type { Locales, ShortLocales } from 'types/commons';

export const getUserLocale = () => {
  let locale = (navigator.languages || [])[0] || navigator.language || 'en';
  if (locale.includes('-')) {
    locale = locale.split('-')[0];
  }
  return locale;
};

export const transformLocale = (locale: ShortLocales, symbol?: string) =>
  `${locale}${symbol || '_'}${locale.toUpperCase()}` as Locales;

export const capitalizeString = <S extends string>(str: S) =>
  str.replace(/./, (char) => char.toUpperCase()) as Capitalize<S>;

export const declOfNum = (num: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
  ];
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function invariant(
  condition: unknown,
  message?: string,
  isNeedToNotificate?: boolean
): asserts condition {
  if (!condition) {
    isNeedToNotificate && message && Notification.error(message);
    throw new Error(message);
  }
}
