import { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router';
import { NavLink } from './NavLink';
import { UserApp } from '../../services/auth.service';
import { Moment } from 'moment';
import {
  DashboardLogo,
  DotSquare,
  Exit,
  GuestsIcon,
  MiniBadge,
  Settings,
  GridLogo,
  SeatingChartLogo,
} from '../ICONS/icons';
import { ModeSwitch, Button } from '..';
import { config } from 'config';
import { HideWhen } from 'components/HideWhen';

import styles from './Sidebar.module.scss';
import { useIntl } from 'react-intl';
import { ETranslations } from '../../types/translates';
import dayjs from 'dayjs';
import { useTimeContext } from 'contexts/TimeContext';

interface Props {
  requestsCount: number;
  userApp: UserApp;
  now: Moment;
  logout: () => void;
  isOpen: boolean;
  reset?: () => void;
}

const Clock = () => {
  const [now, setNow] = useState(dayjs.tz());
  const { restaurantTz } = useTimeContext();

  useEffect(() => {
    setNow(dayjs.tz());
    const interval = setInterval(() => {
      setNow(dayjs.tz());
    }, 10e3);
    return () => clearInterval(interval);
  }, [restaurantTz]);
  return (
    <time className={styles.dateTime} dateTime={now.format('HH:mm')}>
      {now.format('dd, D MMM')}
    </time>
  );
};

export const Sidebar: FC<Props> = ({
  userApp,
  now,
  logout,
  isOpen,
  reset,
  requestsCount,
}) => {
  const intl = useIntl();

  const location = useLocation();

  return (
    <aside className={cn(styles.sidebar, isOpen && styles.open)}>
      <nav>
        <NavLink to="/dashboard" className={cn(styles.item)} resetFn={reset}>
          <div className={styles.iconBlock}>
            <DashboardLogo className={styles.icon} />
          </div>
          <span className={styles.title}>
            {intl.formatMessage({ id: ETranslations.SIDEBAR_DASHBOARD })}
          </span>
        </NavLink>

        <NavLink
          to="/hall-scheme"
          className={cn(
            styles.item,
            location.pathname.includes('create-booking') && styles.active
          )}
          resetFn={reset}
        >
          <div className={styles.iconBlock}>
            <DotSquare className={styles.icon} />
          </div>
          <span className={styles.title}>
            {intl.formatMessage({ id: ETranslations.HALL_SCHEME })}
          </span>
        </NavLink>

        <HideWhen condition={!config.hasRequests}>
          <NavLink to="/requests" className={styles.item} resetFn={reset}>
            <div className={styles.iconBlock}>
              <MiniBadge className={styles.icon} />
            </div>
            <span className={styles.title}>
              {intl.formatMessage({ id: ETranslations.PLURAL_REQUESTS_NOM })}
            </span>
            {requestsCount > 0 ? (
              <span className={styles.counter}>{requestsCount}</span>
            ) : null}
          </NavLink>
        </HideWhen>

        <NavLink to="/guests" className={styles.item} resetFn={reset}>
          <div className={styles.iconBlock}>
            <GuestsIcon className={styles.icon} width={48} height={40} />
          </div>
          <span className={styles.title}>
            {intl.formatMessage({ id: ETranslations.GUEST_LIST })}
          </span>
        </NavLink>

        {config.requestGrid && (
          <NavLink to="/grid" className={cn(styles.item)} resetFn={reset}>
            <div className={styles.iconBlock}>
              <GridLogo className={styles.icon} />
            </div>
            <span className={styles.title}>
              {intl.formatMessage({ id: ETranslations.SIDEBAR_GRID })}
            </span>
          </NavLink>
        )}

        <NavLink to="/schedule-landing" className={styles.item} resetFn={reset}>
          <div className={styles.iconBlock}>
            <SeatingChartLogo className={styles.icon} />
          </div>
          <span className={styles.title}>
            {intl.formatMessage({
              id: ETranslations.SIDEBAR_BOARDING_SCHEDULE,
            })}
          </span>
        </NavLink>

        <NavLink to="/settings" className={styles.item} resetFn={reset}>
          <div className={styles.iconBlock}>
            <Settings className={styles.icon} />
          </div>
          <span className={styles.title}>
            {intl.formatMessage({ id: ETranslations.SIDEBAR_HANDLING })}
          </span>
        </NavLink>
      </nav>
      <div className={styles.user}>
        <Button variant="phantom" className={styles.logout} onClick={logout}>
          <Exit />
        </Button>
        <p className={styles.name}>{userApp?.name}</p>
        <Clock />
        <ModeSwitch className={styles.modeSwitch} />
      </div>
    </aside>
  );
};
