import React, {
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { timelineSelectors, useTimelineActions } from 'features/Timeline';

import styles from './timeline.module.scss';
import { useUpdateEffect } from 'react-use';
import { useOptions } from './hooks';
import cn from 'classnames';
import { defer } from 'lodash';
import { HallMode } from 'features/HallSchema';
import { hallModeSelector } from 'features/HallSchema/selectors';
import { selectBooking } from 'features/BookingFormProxy/selectors';
import { appContextSelectors } from 'features/AppContex';
import { useTimeContext } from 'contexts/TimeContext';

interface Props {
  isFetching: boolean;
}

export const TimelineInput: FC<Props> = ({ isFetching }) => {
  const timestamp = useSelector(timelineSelectors.getTimestamp);
  const { serverTz, restaurantTz } = useTimeContext();
  const { setShift, setTimestamp, setIsActualTime } = useTimelineActions();
  const currentTimeRef = useRef<HTMLOutputElement | null>(null);
  const timeLineBarRef = useRef<HTMLSpanElement | null>(null);
  const [value, setValue] = useState(0);
  const [timeLineBarWidth, setTimelineBarWidth] = useState(0);
  const barRef = useRef<HTMLDivElement | null>(null);
  const optionsConfig = useOptions();
  const booking = useSelector(selectBooking);
  const isActualTime = useSelector(timelineSelectors.getIsActualTime);
  const hallMode = useSelector(hallModeSelector);
  const date = useSelector(appContextSelectors.date);

  const currentValue = (tm: number) => {
    const index = optionsConfig.findIndex((el) => {
      if (el?.value >= tm) return true;
    });
    if (index >= 0) return index;
    return 0;
  };

  useEffect(() => {
    // В некоторых компонентах не успевает строиться таймлайн, откладываем его построение
    const searchValue = currentValue(timestamp);
    setValue(searchValue);
    defer(() => {
      if (!barRef.current) return;
      setTimelineBarWidth(barRef.current.offsetWidth);
    });
  }, []);

  useEffect(() => {
    const givenDate = new Date(timestamp);
    const givenDay = givenDate.getDate();
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const diffDays = givenDay - currentDay;

    if (booking.bookingDate) {
      setTimestamp(timestamp);
      return;
    }

    if (
      diffDays > 0
      && hallMode !== HallMode.MANAGERAL_CREATE_BOOKING
      && window.location.hash !== '#/create-booking'
      && window.location.hash !== '#/dashboard'
      && window.location.hash !== '#/requests'
    ) {
      setTimestamp(optionsConfig[0]?.value);
      return;
    }

    if (!diffDays) {
      return;
    }
  }, [date]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const time = Number(e.target.value);
    const selectedTime = optionsConfig[time].value;
    setValue(time);
    setTimestamp(selectedTime);
    setIsActualTime(false);
  };

  // Отслеживаем любое изменение времени и высчитаваем сдвиг.
  useEffect(() => {
    const startWorkingTime = optionsConfig.at(0)?.value;
    if (isFetching || isActualTime || !startWorkingTime) return;
    const dif = (timestamp - startWorkingTime) / (1000 * 60);
    setShift(dif);
  }, [timestamp, isFetching, isActualTime]);

  useUpdateEffect(() => {
    if (isFetching) return;
    const index = currentValue(timestamp);
    if (value !== index) setValue(index);
  }, [timestamp, isFetching, optionsConfig]);

  const currentPositionHandler = () => {
    const currentTime = currentTimeRef.current;
    if (!currentTime) return;
    //
    // //Вычисляем позиционирование для поп-апа с временем
    const min = 0;
    const max = currentTime.offsetWidth;
    const totalCount = optionsConfig.length - 1;
    const step = max / totalCount;
    //Общая дистанция таймлайна
    const total = max;
    // //Текущая позиция на таймлайне. Процент сдвига.
    const percent = (Number(value) * step) / total;
    currentTime.style.display = percent > 1 || percent < 0 ? 'none' : 'flex';
    currentTime.style.left = `${percent * 100}%`;
    currentTime.style.transform = `translateX(-${percent * 100}%)`;

    //Имитируем заполнения таймлайна
    const bar = timeLineBarRef.current;
    if (!bar) return;
    bar.style.width = `${percent * 100}%`;
  };

  useEffect(() => {
    currentPositionHandler();
  }, [optionsConfig.length, value, timestamp]);

  const blackOutZones = useMemo(() => {
    return optionsConfig
      .filter((el) => el.hasSeparator)
      .reduce<{ start: number; end: number }[]>((acc, val, i, arr) => {
        if (i % 2 === 1) return acc;
        const start = currentValue(val.value);
        const end = currentValue(arr[i + 1]?.value) || Infinity;
        const interval = { start, end };
        acc.push(interval);
        return acc;
      }, []);
  }, [optionsConfig, timeLineBarWidth]);

  return (
    <label className={styles.range} id="range">
      <div className={styles.bar} ref={barRef}>
        <span className={styles.fill} ref={timeLineBarRef} />
        {blackOutZones.map(({ start, end }) => {
          const max = timeLineBarWidth;
          const totalCount = optionsConfig.length - 1;
          const step = max / totalCount;
          const width = (end - start) * step;

          const percent = ((Number(start) * step) / max) * 100;
          const left = `${percent}%`;
          const transform = `translateX(${0}%)`;

          return (
            <div
              key={`${start}-${end}`}
              style={{ width, left, transform }}
              className={styles.blackOut}
            />
          );
        })}
      </div>
      <input
        id="timeline"
        className={styles.timeline}
        type="range"
        value={value}
        min={0}
        max={optionsConfig.length - 1}
        list="timeline-range"
        onChange={onChangeHandler}
      />
      <output
        htmlFor="timeline"
        className={cn(
          styles.currentTime,
          !optionsConfig[value]?.shiftName && styles.disabled
        )}
        ref={currentTimeRef}
      >
        <span>{dayjs.tz(Number(timestamp)).format('HH:mm')}</span>
      </output>
    </label>
  );
};
