import { DeepPartial } from '@reduxjs/toolkit';
import moment from 'moment';
import { Booking, BookingOrder } from 'types/booking';
import { TBookingDTO } from 'types/IBookingDTO';
import { Deposit, FormBooking, Contact, Client } from './types';
import { formatPhone } from 'utils';
import dayjs from 'dayjs';

export function castOrderToForm(
  order: DeepPartial<BookingOrder>
): DeepPartial<FormBooking> {
  const {
    bookingDate,
    bookingTime,
    client,
    contact,
    comment,
    depositAmount,
    persons,
    places,
    source,
    useDeposit,
    visitTime,
    orderId,
  } = order;

  const [firstPlace] = [places] || [];
  const deposit: Deposit = {
    use_deposit: useDeposit || false,
    deposit_made: false,
    deposit_amount: depositAmount || 0,
  };
  return {
    bookingId: orderId,
    tables: [places]?.map((table) => table?.id) || [],
    placeId: firstPlace?.place?.id,
    booking_date: bookingDate,
    booking_time: bookingTime,
    client_id: client?.client_id || contact?.id,
    client: client || contact,
    comment,
    persons,
    phone: client?.phone,
    restaurant_id: [places]?.[0]?.place?.restaurantId,
    source,
    tags: [],
    deposit,
    visit_time: visitTime,
  };
}

export function castBookingToForm(
  booking: DeepPartial<Booking>
): DeepPartial<FormBooking> {
  const {
    bookingDate,
    bookingTime,
    client,
    contact,
    comment,
    depositStatus,
    depositAmount,
    useDeposit,
    persons,
    phone,
    places,
    source,
    tags,
    visitTime,
    bookingId,
    notes,
    seatType,
  } = booking;

  const [firstPlace] = places || [];
  const depositMade = !!(depositStatus && depositStatus === 'MADE');
  const deposit: Deposit = {
    use_deposit: useDeposit || false,
    deposit_made: depositMade || false,
    deposit_amount: depositAmount || 0,
  };
  return {
    bookingId,
    tables: places?.map((table) => table?.id),
    placeId: firstPlace?.place?.id,
    booking_date: bookingDate,
    booking_time: bookingTime?.split(':').slice(0, 2).join(':'),
    client_id: client?.client_id || contact?.id,
    client: client || contact,
    comment,
    deposit,
    persons,
    phone: formatPhone(phone),
    restaurant_id: places?.[0]?.place?.restaurantId,
    source,
    tags: tags?.map((tag) => ({
      ...tag,
      description: tag?.name,
      tag_id: tag?.id,
      value: tag?.id,
    })),
    visit_time: visitTime,
    notes: Object.values(notes || {}).join(', '),
    rawNotes: notes,
    seatType,
  };
}

export const castFormToUpdateDTO = (formData: FormBooking): TBookingDTO => {
  const {
    placeId,
    tables: tablesSrc,
    hostess,
    tags,
    client,
    time,
    bookingId,
    date,
    deposit,
    restaurant_id,
    client_id,
    notes,
    rawNotes,
    ...other
  } = formData;
  const { use_deposit, deposit_made, deposit_amount } = deposit;
  const tables = [];
  if (placeId) {
    tables.push({
      place_id: placeId,
      table_ids: tablesSrc,
    });
  }
  const timestampDate = moment(Number(time.value));

  return {
    tables,
    restaurant_id,
    ...other,
    user_id: hostess.id,
    notes: rawNotes,
    client,
    use_deposit,
    deposit_status: deposit_made,
    deposit_amount,
    tags: tags.map((t) => ({
      description: t.description || t.label,
      color: t.color,
      tag_id: t.value,
    })),
    booking_time: timestampDate.isValid()
      ? timestampDate.format('HH:mm:ss')
      : moment(time.label, 'HH:mm').format('HH:mm:ss'),
    booking_date: timestampDate.isValid()
      ? timestampDate.format('YYYY-MM-DD')
      : moment(date).format('YYYY-MM-DD'),
  };
};

const getTimeObject = (str: string) => ({
  hour: +str.substring(0, 2),
  minute: +str.substring(3, 5),
  seconds: 0,
});
const nextTime = () => {
  const now = dayjs().tz();
  const diff = now.minute() % 15;
  return now.clone().add(diff, 'minute').format('HH:mm');
};

export const castFormToCreateDTO = (
  formData: FormBooking
): Partial<TBookingDTO> => {
  const {
    placeId,
    tables,
    hostess,
    persons,
    client,
    client_id,
    bookingId,
    deposit,
    comment = '',
    phone,
    restaurant_id,
    time,
    seatType,
    contact,
    ...rest
  } = formData;

  let date = dayjs(Number(time?.value)).tz();

  if (!date.isValid()) {
    const timeString = time?.label || nextTime();
    const { hour, minute, seconds } = getTimeObject(timeString);
    date = dayjs(rest.date)
      .tz()
      .set('hour', hour)
      .set('minute', minute)
      .set('seconds', seconds);
  }
  const tags
    = rest.tags?.map((t) => ({
      description: t.label,
      color: t.color,
      tag_id: t.value,
    })) || [];
  const source = rest.source
    ? { id: rest.source.id, source_name: rest.source.source_name }
    : null;
  const { use_deposit, deposit_made, deposit_amount } = deposit;

  return {
    booking_date: date.format('YYYY-MM-DD'),
    booking_time: date.format('HH:mm:ss'),
    client_id: client_id || client?.client_id,
    phone,
    client,
    contact,
    comment,
    deposit_amount,
    use_deposit,
    deposit_status: deposit_made,
    persons: Number(persons),
    seatType,
    restaurant_id,
    // @ts-ignore
    source,
    tables: [
      {
        place_id: placeId,
        table_ids: tables,
      },
    ],
    tags,
    user_id: hostess.id,
    visit_time: rest.visit_time,
  };
};
