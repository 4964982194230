import React, { FC, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { defer } from 'lodash';
import styles from './timeline.module.scss';
import { useOptions } from './hooks';
import { useTimeContext } from 'contexts/TimeContext';

let isShowCounter = 0;

export const TimelineOptions: FC = () => {
  const timelineRef = useRef<HTMLDataListElement>(null);
  const [optionsWidth, setOptionsWidth] = useState<number | null>(null);
  const optionsConfig = useOptions();
  const { serverTz, restaurantTz } = useTimeContext();

  useEffect(() => {
    // В некоторых компонентах не успевает строиться таймлайн, откладываем его построение
    defer(() => {
      if (!timelineRef.current) return;
      setOptionsWidth(timelineRef.current.offsetWidth);
    });
  }, []);

  // Высчитываем позицию лейбла на таймлайне
  const getPositionLabel = (value: number) => {
    if (!optionsWidth) return 0;
    const max = optionsWidth;
    const totalCount = optionsConfig.length - 1;
    const step = max / totalCount;
    const percent = (value * step) / max;
    return percent * 100;
  };

  return (
    <datalist id="timeline-range" className={styles.time} ref={timelineRef}>
      {optionsConfig.map(
        ({ value: el, isShow, shiftName, isShowShiftName }, i) => {
          const position = getPositionLabel(i);
          if (isShow) isShowCounter += 1;
          return (
            <span
              key={el + i}
              style={{
                left: position + '%',
                transform: `translateX(-${position}%)`,
              }}
              className={cn(styles.option)}
            >
              <span
                className={cn(
                  styles.label,
                  isShow && styles.labelShow,
                  isShowCounter % 2 && styles.labelUneven
                )}
              >
                {dayjs.tz(el, restaurantTz).format('HH:mm')}
              </span>
              {isShowShiftName && (
                <span className={styles.shiftName}>{shiftName}</span>
              )}
            </span>
          );
        }
      )}
    </datalist>
  );
};
