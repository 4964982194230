import React, {FC} from 'react';
import {useSliderDatetime} from 'hooks/useSliderDatetime';
import {getSlotExtraOptions, selectSlotByShift, sumOfSquares} from '../../Table/utils';
import {SlotEvent, TTable} from 'features/api/hallschema-api';
import {calculatePath, getCenterCoords} from './utils';
import {IPosition} from '@technolab/hse';
import cn from 'classnames';
import styles from './style.module.scss'
import { useTableFigures } from 'features/api/tables-api';
import { getStatusColor } from '../BookingTable/utils';
import { useSelector } from 'react-redux';
import { timelineSelectors } from 'features/Timeline';
import { useStatusColor } from '../BookingTable/useStatusColor';
import dayjs from 'dayjs';

interface Props {
  slots: SlotEvent[];
  places: Record<number, TTable>
}

export const TablePathSvg: FC<Props> = ({slots, places}) => {
  const timeWithDate = useSliderDatetime();
  const slot = selectSlotByShift(slots, dayjs(timeWithDate().toString()).tz());
  const isActualTime = useSelector(timelineSelectors.getIsActualTime);
  const {data: figures, defaultFigure} = useTableFigures();
  const {tableColor} = getSlotExtraOptions(slot, dayjs(timeWithDate().toString()).tz());
  const statusColor = useStatusColor(slot?.booking, isActualTime, tableColor)
  
  if (!slot || !figures) return null;

  // Хрупкое место происходит мердж столов их схемы со столами из брони
  const bookingTables = slot.booking.places
    .map(table => places[table.id])
    .sort((a, b) => {
      const sumA = sumOfSquares(a.position!.x, a.position!.y);
      const sumB = sumOfSquares(b.position!.x, b.position!.y);
      return sumA - sumB;
    })
    .reduce<Record<'start' | 'end', IPosition>[]>((acc, value, i, array) => {
      if(!value?.figure_id) return acc
      const figureConfig
        = figures.find(
          (figure) => Number(figure.figure_id) === value.figure_id
        ) || defaultFigure;
      const start = getCenterCoords(value.position, figureConfig);
      const end = getCenterCoords(array[i + 1]?.position, figureConfig);

      if (start && end) {
        acc.push({start, end});
      }
      return acc
    },[])


  return (
    <g className={cn(styles.path, styles[tableColor])}>
      {bookingTables.map(({start, end}) => (
        <path
        stroke={statusColor}
          key={`${start.x}-${start.y}`}
          d={calculatePath(start, end)}
        />
      ))}
    </g>
  );
};
