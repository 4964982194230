import { createContext, useContext } from 'react';
import { invariant } from 'utils';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type TimeContext = {
  serverTz: string;
  localTz: string;
  restaurantTz: string;
  convertToRestaurant: (date: string | number | Date | Dayjs) => Dayjs;
  convertToLocal: (date: string | number | Date | Dayjs) => Dayjs;
  convertToServer: (date: string | number | Date | Dayjs) => Dayjs;
};

export const timeContext = createContext<TimeContext | null>(null);

export const useTimeContext = () => {
  const value = useContext(timeContext);
  return (value
    || invariant(
      value,
      'TimeContext context not passed, please wrap your components with <TimeContextProvider />'
    )) as TimeContext;
};
