import { useCurrentHallShiftsSchedule } from 'features/api/hallschema-api';
import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useFetchShiftsRulesQuery } from 'features/api/shifts';
import { useSelector } from 'react-redux';
import { appContextSelectors } from 'features/AppContex';
import { useTimelineActions } from 'features/Timeline';

export type TOptionsConfig = {
  value: number;
  isShow: boolean;
  hasSeparator: boolean;
  shiftName: string;
  isShowShiftName: boolean;
};

const calculateTimestamps = ({
  startTimeStamp,
  endTimeStamp,
  timeInterval,
}: {
  startTimeStamp: number;
  endTimeStamp: number;
  timeInterval: number;
}) => {
  const timeIntervalMs = timeInterval * 60 * 1000;
  const values = new Array<number>();
  for (
    let timestamp = startTimeStamp;
    timestamp <= endTimeStamp;
    timestamp += timeIntervalMs
  ) {
    values.push(timestamp);
  }
  return values;
};

export function useOptions() {
  const place_id = useSelector(appContextSelectors.place);
  const date = useSelector(appContextSelectors.date);
  const restaurant = useSelector(appContextSelectors.restaurant);
  const { data } = useFetchShiftsRulesQuery({
    restaurant_id: restaurant.restaurant_id,
    date: date.format('YYYY-MM-DD'),
    place_id,
  });
  const { setTimelineConfig } = useTimelineActions();

  const formattedNewData = data
    ?.reduce<
      {
        values: number[];
        interval: number;
        zone: 'BLACKOUT' | 'BOOK';
        shift_name: string;
      }[]
    >((acc, shiftData, index, arr) => {
      const startTime = dayjs.tz(`${shiftData.date}T${shiftData.low_bounder}`);
      const startTimeStamp = startTime.valueOf();

      const endTime = dayjs.tz(`${shiftData.date}T${shiftData.high_bounder}`);
      // Если друг endTime меньше, чем startTime, это означает, что endTime заканчивается в следующем дне
      const endTimeStamp = endTime.isBefore(startTime)
        ? endTime.add(1, 'day').valueOf()
        : endTime.valueOf();

      const values = calculateTimestamps({
        startTimeStamp,
        endTimeStamp,
        timeInterval: shiftData.time_interval,
      });

      acc.push({
        values,
        interval: shiftData.time_interval,
        zone: 'BOOK',
        shift_name: shiftData.shift_name,
      });
      const nextShift = arr.at(index + 1);
      if (nextShift) {
        const blackOutValues = calculateTimestamps({
          startTimeStamp: endTimeStamp + shiftData.time_interval * 60 * 1000,
          endTimeStamp:
            dayjs.tz(`${nextShift.date}T${nextShift.low_bounder}`).valueOf()
            - shiftData.time_interval * 60 * 1000,
          timeInterval: nextShift.time_interval,
        });
        acc.push({
          values: blackOutValues,
          interval: nextShift.time_interval,
          zone: 'BLACKOUT',
          shift_name: 'null',
        });
      }
      return acc;
    }, [])
    .sort((a, b) => a.values[0] - b.values[0]);

  useEffect(() => {
    if (formattedNewData) {
      const dateTimeStart = dayjs.tz(formattedNewData.at(0)?.values.at(0));
      const dateTimeEnd = dayjs.tz(formattedNewData.at(-1)?.values.at(-1));
      const timeStart = dateTimeStart.hour() + dateTimeStart.minute() / 60;

      const timelineLengthHours = dateTimeEnd.diff(dateTimeStart, 'h', true);

      setTimelineConfig({ timeStart, timelineLengthHours });
    }
  }, [data]);

  const optionsConfig = useMemo(() => {
    if (!formattedNewData) return [];

    const times = formattedNewData.reduce<TOptionsConfig[]>(
      (acc, val, idx, array) => {
        const isFirstShift = idx == 0;
        const isLastShift = idx === array.length - 1;
        const isBlackOut = val.zone === 'BLACKOUT';

        // У центрального элемента будет показываться название шифта
        const middleItem = Math.floor(val.values.length / 2);
        const prevZoneIsBook = idx > 0 && array[idx - 1]?.zone === 'BOOK';

        // Достаем первое и последние значение и проверяем являются ли они не полным часом
        const firstTimeInShift = val.values[0];
        const lastTimeInShift = val.values[val.values.length - 1];
        const firstTimeIsNotWhole
          = firstTimeInShift && dayjs(firstTimeInShift).format('mm') !== '00';
        const lastTimeIsNotWhole
          = lastTimeInShift && dayjs(lastTimeInShift).format('mm') !== '00';

        val.values.forEach((timestamp, i, arr) => {
          const isStartTime = i === 0;
          const isEndTime = i === arr.length - 1;
          const isFirstOrLastElem = isStartTime || isEndTime;
          const hasSeparator
            = (!isFirstShift && isStartTime) || (!isLastShift && isEndTime);

          // Проверка нужно ли скрывать значения с полным часом если шифт начинается или заканчивает не полным часом
          // К примеру если шифт начинается с 09:45 то значение лейбл с 10:00 надо скрыть
          // Если шифт заканчивается в 23:45 то значение 23:00 надо скрыть
          const hidePreviousThreeTimes
            = lastTimeIsNotWhole && i + 3 >= arr.length - 1 && !isEndTime;
          const hideNextThreeTimes
            = firstTimeIsNotWhole && i - 3 <= 0 && !isStartTime;

          if (isBlackOut) {
            (i < 3 || i > arr.length - 3)
              && acc.push({
                value: timestamp,
                isShow: false,
                hasSeparator: false,
                shiftName: val.shift_name,
                isShowShiftName: false,
              });
          } else {
            const isWholeHour = dayjs(timestamp).format('mm') === '00';
            const isShowShiftName = i === middleItem;
            acc.push({
              value: timestamp,
              isShow:
                // Если это первый или последний элемент, то показываем значение.
                // Либо если это целый час (Ниже исключения когда целый час не показывается)
                (isWholeHour || isFirstOrLastElem)
                && !hidePreviousThreeTimes
                && !hideNextThreeTimes
                && !(prevZoneIsBook && isStartTime),
              hasSeparator,
              isShowShiftName,
              shiftName: val.shift_name,
            });
          }
        });
        return acc;
      },
      []
    );
    return times;
  }, [data]);

  return optionsConfig;
}
