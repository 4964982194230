import { useSelector } from 'react-redux';
import { timeContext } from './context';
import { appContextSelectors } from 'features/AppContex';
import dayjs, { type Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import type { ReactNode } from 'react';

dayjs.extend(utc);
dayjs.extend(timezone);

const SERVER_TZ = 'Europe/Moscow';

const TimeContextProvider = ({ children }: { children: ReactNode }) => {
  const restaurant = useSelector(appContextSelectors.restaurant);
  const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return (
    <timeContext.Provider
      value={{
        serverTz: SERVER_TZ,
        localTz,
        restaurantTz: restaurant?.timezone || SERVER_TZ,
        convertToRestaurant: (date: string | number | Date | Dayjs) =>
          dayjs(date).tz(),
        convertToServer: (date: string | number | Date | Dayjs) =>
          dayjs(date).tz(SERVER_TZ),
        convertToLocal: (date: string | number | Date | Dayjs) =>
          dayjs(date).tz(localTz),
      }}
    >
      {children}
    </timeContext.Provider>
  );
};

TimeContextProvider.displayName = 'TimeContextProvider';

export { TimeContextProvider };
